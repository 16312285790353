<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back()"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.recharge") }}</li>
      </ul>
    </div>
    <div class="main main_page">
      <!-- 选择Pay Online跳转到支付通道，选择Pay Via Bank跳转到 RechargeBank.vue -->
      <div class="Recharge_two Recharge_two_b">
        <ul>
          <li v-if="channel?.onlinePayChannelVoList?.length">
            <label for="RadioGroup1_0">
              <input
                type="radio"
                name="RadioGroup1"
                v-model="formModel.payMethod"
                value="channel"
                id="RadioGroup1_0"
              />
              <p>
                <b class="iconfont icon-wangyin2"></b>
                <span>{{ t("common.rechargeWay.PayOnline") }}</span>
                <i class="iconfont icon-duigou"></i>
              </p>
            </label>
          </li>
          <li v-if="channel?.localPayChannelPoList?.length">
            <label for="RadioGroup1_1">
              <input
                type="radio"
                name="RadioGroup1"
                v-model="formModel.payMethod"
                value="bank"
                id="RadioGroup1_1"
              />
              <p>
                <b class="iconfont icon-yinhangka"></b>
                <span>{{ t("common.rechargeWay.PayViaBank") }}</span>
                <i class="iconfont icon-duigou"></i>
              </p>
            </label>
          </li>
          <li v-if="channel?.coinPayChannelVoList?.length">
            <label for="RadioGroup1_1">
              <input
                type="radio"
                name="RadioGroup1"
                v-model="formModel.payMethod"
                value="coinPay"
                id="RadioGroup1_2"
              />
              <p>
                <b class="iconfont icon-shejiaotubiao-69"></b>
                <span>{{ t("extra.virtualCurrency") }}</span>
                <span v-if="formModel.isShowGift" class="gift">{{ t("common.rechargeWay.Gift") }}</span>
                <i class="iconfont icon-duigou"></i>
              </p>
            </label>
          </li>
        </ul>
      </div>
      <div v-if="false" class="Rech">
      <!-- <div v-if="channel?.localPayChannelPoList?.length" class="Rech"> -->
        <AForm
          layout="vertical"
          :model="formModel"
          ref="formRef"
          validateTrigger="blur"
          autocomplete="off"
        >
          <div class="Rech_box" v-show="formModel.payMethod === 'bank'">
            <h1>{{ t("profile.label.rechargeInstructions") }}</h1>

            <div class="Pay_usdt_box" id="input_C">
              <ul class="amountUl">
                <li class="flexLi">
                  <b>{{ CURRENCY_SYMBOL }}</b>
                  <AFormItem name="tradeMoney">
                    <AInput
                      type="number"
                      step="any"
                      v-model:value="formModel.amount"
                      :placeholder="t('profile.placeholder.rechargeAmount')"
                    />
                  </AFormItem>
                </li>
              </ul>
            </div>
            
            <ol class="ol_one" v-if="sysDataDictionaryWbObj.NATION?.dataContent === 'NIGERIA'">
              <li v-for="(item, index) in amounts" :key="item">
                <label :for="`checkbox-1-${index}`">
                  <input
                    v-model="formModel.amount"
                    type="radio"
                    :value="item"
                    name="checkbox-1"
                    :id="`checkbox-1-${index}`"
                  />
                  <div>
                    <p>{{ CURRENCY_SYMBOL }}{{ item }}</p>
                  </div>
                </label>
              </li>
            </ol>
            <ol class="ol_one"  v-if="sysDataDictionaryWbObj.NATION?.dataContent === 'BRAZIL'">
              <li v-for="(item, index) in amounts_pb" :key="item">
                <label :for="`checkbox-1-${index}`">
                  <input
                    v-model="formModel.amount"
                    type="radio"
                    :value="item"
                    name="checkbox-1"
                    :id="`checkbox-1-${index}`"
                  />
                  <div>
                    <p>{{ CURRENCY_SYMBOL }}{{ item }}</p>
                  </div>
                </label>
              </li>
            </ol>
            <ol class="ol_one"  v-if="sysDataDictionaryWbObj.NATION?.dataContent === 'MEXICO'">
              <li v-for="(item, index) in amounts_pb" :key="item">
                <label :for="`checkbox-1-${index}`">
                  <input
                    v-model="formModel.amount"
                    type="radio"
                    :value="item"
                    name="checkbox-1"
                    :id="`checkbox-1-${index}`"
                  />
                  <div>
                    <p>{{ CURRENCY_SYMBOL }}{{ item }}</p>
                  </div>
                </label>
              </li>
            </ol>

            <ol class="ol_one"  v-if="sysDataDictionaryWbObj.NATION?.dataContent === 'INDONESIA'">
              <li v-for="(item, index) in amounts_ind" :key="item">
                <label :for="`checkbox-1-${index}`">
                  <input
                    v-model="formModel.amount"
                    type="radio"
                    :value="item"
                    name="checkbox-1"
                    :id="`checkbox-1-${index}`"
                  />
                  <div>
                    <p>{{ CURRENCY_SYMBOL }}{{ item }}</p>
                  </div>
                </label>
              </li>
            </ol>
          </div>
        </AForm>
      </div>
      <div class="Sys_submit_btn recharge">
        <button @click="handleRecharge">
          {{ t("common.button.confirm") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import { useI18n } from "@/lang";
import { CURRENCY_SYMBOL } from "@/utils/constant";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
import api from "@/api";
export default defineComponent({
  name: "Recharge",
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const amounts = [1000, 2000, 5000, 10000, 30000, 100000];
    const amounts_pb = [50, 100, 500, 1000, 3000, 8000, 15000];
    const amounts_ind = [200000, 500000, 2500000, 5000000, 15000000, 30000000, 50000000];
    const channel = ref(null);
    const formModel = reactive({
      amount: null,
      payMethod: "",
      isShowGift:false
    });
    onMounted(async () => {
      let res = await api.getPayChannelList();
      if (res?.data?.success) {
        channel.value = res.data.data;
        if(channel.value && channel.value.coinPayChannelVoList.length > 0){
          for (let i = 0; i < channel.value.coinPayChannelVoList.length; i++) {
            if(parseFloat(channel.value.coinPayChannelVoList[i].giftRatio) > 0){
              formModel.isShowGift = true
              break;
            }            
          }
        }
        // console.log(res.data.data,'ssss',channel.value.coinPayChannelVoList)
      }
    });
    async function handleRecharge() {
      try {
        switch (formModel.payMethod) {
          case "":
            message.info(t("error.recharge.noChannel"));
            break;
          case "bank": {
            // if (!formModel.amount) {
            //   message.info(t("error.recharge.noAmount"));
            //   return;
            // }
            // await router.replace({
            //   path: `/recharge_via_bank/${formModel.amount}`,
            // });
            await router.replace({
              path: `/recharge_via_bank/1`,
            });
            break;
          }
          case "channel": {
            await router.replace({
              path: `/recharge_online`,
            });
            break;
          }
          case "coinPay": {
            await router.replace({
              path: "/coinPay",
            });
          }
        }
      } catch (e) {
        if (e.errorFields && e.errorFields.length > 0) {
          let errorField = e.errorFields[0];
          let errorMessage = errorField.errors[0];
          message.info({ content: errorMessage, key: "recharge" });
        }
      }
    }
    return {
      t,
      formModel,
      handleRecharge,
      CURRENCY_SYMBOL,
      amounts,
      amounts_pb,
      amounts_ind,
      channel,
      sysDataDictionaryWbObj
    };
  },
});
</script>

<style lang="scss">
.Rech {
  #input_C {
    margin-bottom: 20px;
  }
  .amountUl {
    color: red;
    b {
      font-size: 20px;
      line-height: 38px;
    }
    .flexLi {
      display: flex;
    }
    input {
      color: red !important;
      margin-left: 18px !important;
      font-size: 18px !important;
      padding-bottom: 0px !important;
    }
  }
}
</style>
